export default {
  archiveFolderList: (state) => state.archiveFolderList,
  getArchiveFolderLoader: (state) => state.folderSkeleton,
  selectedArchiveFolder: (state) => state.selectedArchiveFolder,
  folderContentList: (state) => state.folderContentList,
  fetchContentLoader: (state) => state.fetchContentLoader,
  selectedArchiveContentType: (state) => state.selectedArchiveContentType,
  archiveContentTotalCount: (state) => state.archiveContentTotalCount,
  archiveContentCurrentPage: (state) => state.currentArchiveContentPage,
  archiveArticlePageSize: (state) => state.archiveArticlePageSize,
  archiveVideoAndPodcastPageSize: (state) => state.archiveVideoAndPodcastPageSize,
  hasMoreArchiveContent: (state) => state.hasMoreArchiveContent,
  archiveSuggestedFolderContentList: (state) => state.archiveSuggestedFolderContentList
};
