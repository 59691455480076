export default {
  SET_ARCHIVE_FOLDER_LIST(state, archiveFolderList) {
    state.archiveFolderList = archiveFolderList;
  },
  ADD_NEW_FOLDER_TO_ARCHIVE(state, newFolder) {
    
    if (!newFolder.parent){
      state.archiveFolderList = [...state.archiveFolderList, newFolder]
    }
    else {
      state.archiveFolderList = state.archiveFolderList.map(folder=>{
        if (folder.id === newFolder.parent)
          return {
            ...folder,
            child: folder.child ? [...folder.child, newFolder] : []
          }
        else{
          return {...folder}
        }
      })
    }
  },

  SET_FOLDER_SKELETON(state, payload) {
    state.folderSkeleton = payload;
  },
  SET_SELECTED_FOLDER(state, folder) {
    state.selectedArchiveFolder = folder;
  },
  SET_ARCHIVE_FOLDER_CONTENT(state, folderContent) {
    /**
     * this mutation sets a new array or updates the existing object with in array or add a new element to the array
     */
    if(Array.isArray(folderContent))
      state.folderContentList = folderContent;
    else{
        const existingContent = state.folderContentList.find(content => content.id === folderContent)
        if (!existingContent)
            state.folderContentList = [...state.folderContentList, {...folderContent}]
        else
          state.folderContentList = state.folderContentList.map(content=>{
            return content.id === folderContent ? {...folderContent} : {...content}
          })
    }

  },
  SET_FETCH_CONTENT_LOADER(state) {
    state.fetchContentLoader = !state.fetchContentLoader;
  },
  UPDATE_ARCHIVE_FOLDER(state, payload) {
    state.archiveFolderList = state.archiveFolderList.map((folder) => {

      if (payload.parent){
        return folder.id === payload.parent ? {
          ...folder,
            child: folder.child ? folder.child.map(child=>{
                 return child.id === payload.id ? payload : child
                }) : []
        } : 
        {
            ...folder
        }
        
      }else{
        return folder.id === payload.id ? {...folder, name:payload.name} : {...folder}
      }

    })
  },
  DELETE_ARCHIVE_FOLDER(state, folderId) {
    const isParent = state.archiveFolderList.findIndex(folder=>folder.id === folderId)
    if (isParent > -1)
      state.archiveFolderList = state.archiveFolderList.filter((folder) => folder.id !== folderId)
    else
    state.archiveFolderList = state.archiveFolderList.map(folder=>{
        
      return {
        ...folder,
        child: folder.child ? folder.child.filter(child=>child.id !== folderId) : []
      }
        
      })
  },
  DELETE_ARCHIVE_FOLDER_ARTICLE(state, contentId) {
    state.folderContentList = state.folderContentList.filter((content) => content.id !== contentId)
  },
  EMPTY_ARCHIVE_CONTENT_LIST(state){
    state.folderContentList = []
  },
  SET_ACTIVE_ARCHIVE_CONTENT_TYPE(state, contentType){
    state.selectedArchiveContentType = contentType
  },
  EMPTY_SELECTED_ARCHIVE_FOLDER(state){
    state.selectedArchiveFolder = {}
  },
  SET_ARCHIVE_CONTENT_CURRENT_PAGE (state, page) {
    state.currentArchiveContentPage = page
  },
  SET_ARCHIVE_CONTENT_TOTAL_COUNT (state, count) {
    state.archiveContentTotalCount = count
  },
  SET_ARCHIVE_CONTENT_END_LIMIT (state, hasMoreArchiveContent){
    state.hasMoreArchiveContent = hasMoreArchiveContent
  },
  SET_ARCHIVE_FOLDER_SUGGESTED_CONTENT(state, payload=[]){
    state.archiveSuggestedFolderContentList = payload
  }
  

};
